<template>
  <div>
    <MyHeader />
    <main id="content">
      <ProfileMenu />
      <div class="container mt-4 mt-lg-5">
        <div class="row justify-content-between align-items-center">
          <div class="col pr-0 pr-md-3 col-md-auto">
            <div class="account-profile">
              <div class="pic">
                <img :src="user.avatar ? user.avatar.path : '/img/user-thumb.jpg'">
              </div>
              <div class="info">
                <h1>{{ user.name }} {{ user.surname }}</h1>
                <p>{{ user.country ? user.country.name : '' }} · Joined in {{ user.created_at|beatyDate }}</p>
              </div>
            </div>
          </div>
          <div class="col-2 col-md-auto px-0 px-md-3">
            <router-link class="btn btn-block"
                         :to="{ name: 'user-edit-profile' }">
              <i class="icon-edit" /> <span class="d-none d-md-inline-block">Edit Profile</span>
            </router-link>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-4 pr-xl-5">
            <h5>General Information</h5>
            <hr>
            <ul class="account-info-list">
              <li><small>Email</small>{{ user.email }}</li>
              <li><small>Phone:</small>{{ user.area_code }}-{{ user.phone }}</li>
              <!-- <li><small>Date of Birth:</small>{{ user.birthday ? user.birthday.day : '?' }} {{ user.birthday ? user.birthday.month : '?' }} {{ user.birthday ? user.birthday.year : '?' }}</li> -->
              <li><small>Date of Birth:</small>{{ user.birthday }}</li>
              <li><small>Gender:</small>{{ user.gender }}</li>
            </ul>
          </div>
          <div class="col-12 col-sm-6 col-lg-4 px-xl-5 border-lg">
            <h5>Others</h5>
            <hr>
            <ul class="account-info-list">
              <li><small>Country</small>{{ user.country ? user.country.name : '' }}</li>
              <li><small>School</small>{{ user.school }}</li>
              <li><small>Work</small>{{ user.company }}</li>
            </ul>
          </div>
          <div class="col-12 col-sm col-lg-4 pl-xl-5">
            <h5>Verifications</h5>
            <hr>
            <ul class="account-info-list">
              <li class="d-flex justify-content-between align-items-center">
                <div>
                  <small>Email Address</small>
                  <a href="javascript:;"
                     @click="mailConfirmation()"
                     :class="{ 'disable-click': user.is_email_verified === 1}">
                    <span v-if="!checkMessage"
                          :class="{ 'text-primary': user.is_email_verified === 1, 'text-danger': user.is_email_verified === 0, 'text-warning': user.is_email_verified === 2 }">{{ user.is_email_verified === 1 ? 'Verified' : user.is_email_verified === 2 ? 'Pending Verification' : 'Unverfied' }}</span>
                    <span v-else
                          class="text-warning">Please check your email</span>
                  </a>
                </div>
                <i v-if="user.is_email_verified === 0"
                   class="far fas fa-times" />
                <i v-if="user.is_email_verified === 1"
                   class="icon-check" />
                <i v-if="user.is_email_verified === 2"
                   class="far fa-clock" />
              </li>

              <li class="d-flex justify-content-between align-items-center"
                  v-if="user.government">
                <div>
                  <small>Government ID</small>
                  <span v-if="user.is_government_verified === 1"
                        class="text-primary">
                    Verified
                  </span>
                  <span v-else>
                    <a
                      class="text-danger"
                      href="javascript:;"
                      @click="isTruliooOpen = true">
                      Unverfied
                    </a>
                  </span>
                </div>
                <i v-if="user.is_government_verified === 0"
                   class="far fas fa-times" />
                <i v-if="user.is_government_verified === 1"
                   class="icon-check" />
                <!-- <i v-if="user.is_government_verified === 2"
                   class="far fa-clock" /> -->
              </li>

              <li class="d-flex justify-content-between align-items-center">
                <div>
                  <small>Facebook</small> <span :class="{ 'text-primary': user.is_facebook_verified === 1, 'text-danger': user.is_facebook_verified === 0 }">{{ user.is_facebook_verified === 1 ? 'Verified' : 'Unverfied' }}</span>
                </div>
                <v-facebook-login v-if="!user.is_facebook_verified"
                                  v-model="userFacebook"
                                  @login="onLogin"
                                  app-id="237975390608122"
                                  class="btn btn-sm" />
                <i v-if="user.is_facebook_verified === 0"
                   class="far fas fa-times" />
                <i v-if="user.is_facebook_verified === 1"
                   class="icon-check" />
              </li>
            </ul>
            <form v-if="user && !user.is_government_verified && isTruliooOpen">
              <h5>Document Type</h5>
              <div class="form-group">
                <select v-model="government.documentType"
                        class="form-control">
                  <!-- <option value="document"
                          disabled>
                    Document
                  </option> -->
                  <option v-for="(document, index) in countryDocuments"
                          :key="index"
                          :value="document">
                    {{ document }}
                  </option>
                </select>
              </div>
              <h3>Front Image</h3>
              <div class="form-group">
                <img :src="government.frontImage"
                     class="img-fluid">
                <input type="file"
                       @change="handleImageToBase64($event, 'front_image')"
                       accept="image/*"
                       class="form-control"
                       capture="camera">
              </div>
              <h5>Back Image</h5>
              <div class="form-group">
                <img :src="government.backImage"
                     class="img-fluid">
                <input type="file"
                       @change="handleImageToBase64($event, 'back_image')"
                       accept="image/*"
                       class="form-control"
                       capture="camera">
              </div>
              <h5>Live Image</h5>
              <div class="form-group">
                <img :src="government.liveImage"
                     class="img-fluid">
                <input type="file"
                       @change="handleImageToBase64($event, 'live_image')"
                       accept="image/*"
                       class="form-control"
                       capture="camera">
              </div>
              <button @click="truliooConfirmation"
                      type="button"
                      class="btn">
                Send
              </button>
              <button @click="isTruliooOpen = false"
                      type="button"
                      class="btn">
                Close
              </button>
              <div class="errors"
                   v-if="truliooErrors.length > 0">
                <div v-for="(error, index) in truliooErrors"
                     :key="index"
                     class="alert alert-danger"
                     role="alert">
                  {{ error.message }}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ContactOurExperts />
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import VFacebookLogin from 'vue-facebook-login-component';
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import ContactOurExperts from '../../components/users/ContactOurExperts.vue';

export default {
  name: 'UserProfile',
  components: {
    ProfileMenu,
    ContactOurExperts,
    VFacebookLogin,
  },
  data() {
    return {
      user: {},
      userFacebook: null,
      checkMessage: false,
      FB: null,
      scope: null,
      government: {
        frontImage: null,
        backImage: null,
        liveImage: null,
        documentType: 'IdentityCard',
      },
      countryDocuments: [],
      avatarController: false,
      truliooErrors: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: '/',
        maxFiles: 1,
      },
      isTruliooOpen: false,
    };
  },
  computed: {
    isTruliooDisabled() {
      return !!(this.government.frontImage && this.government.backImage && this.government.liveImage);
    }
  },
  methods: {
    ...mapGetters([
      'getUser',
    ]),
    mailConfirmation() {
      this.checkMessage = true;
      this.$axios.post('/v1/account/email/confirmation').then(() => {});
    },
    onLogin(response) {
      const formData = new FormData();
      formData.append('facebook_verification', response.authResponse.userID);
      this.$axios.post('/v1/account/facebook/confirmation', formData).then((resp) => {
        this.$store.commit('setUser', resp.data.data);
        this.user = resp.data.data;
        Promise.resolve();
      }).catch(() => {
      });
    },
    truliooConfirmation() {
      const formData = new FormData();
      formData.append('front_image', this.government.frontImage);
      formData.append('back_image', this.government.backImage);
      formData.append('live_image', this.government.liveImage);
      formData.append('document_type', this.government.documentType);
      this.$axios.post('/v1/account/trulioo/confirmation', formData).then(() => {
        this.errors = null;
        Promise.resolve();
      }).catch((error) => {
        this.errors = error.response;
      });
    },
    handleImageToBase64(e, name) {
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage, name);
    },
    createBase64Image(fileObject, name) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (name === 'front_image') {
          this.government.frontImage = e.target.result;
        } else if (name === 'back_image') {
          this.government.backImage = e.target.result;
        } else if (name === 'live_image') {
          this.government.liveImage = e.target.result;
        }
      };
      reader.readAsDataURL(fileObject);
    },
  },
  created() {
    if (this.$route.query.email_confirmation && this.$route.query.email_confirmation === '1') {
      this.$axios.get('/v1/account/profile').then((profile) => {
        this.$store.commit('setUser', profile.data.data);
      });
    }
    this.user = this.getUser();
    this.countryDocuments = this.user.government && this.user.government.country_documents ? this.user.government.country_documents.split(',') : '';
  },
};
</script>

<style scoped>
.disable-click {
  pointer-events: none;
}
select {
  color: #595959 !important;
}
</style>
